#teamForm {
  margin-top: 32px;

  md-input-container {
    margin: 0px;
  }
  md-autocomplete {
    background: none;
  }
}

.row-with-padding {
  padding-bottom: 0.5rem;
}

.row-with-margin {
  margin-bottom: 32px;
}

#regForm {
  .input-w-icon__input {
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    line-height: 120%;
    text-transform: none;
  }

  .registration-candidate-list,
  #athletes,
  #teams,
  #categories,
  #sub-categories,
  #sports {
    input[type='radio'] {
      -webkit-appearance: none;
      display: inline;
    }
    label {
      //width: 100%;
      //height: 100%;
      //padding: 16px;
      //display: block;
      //font-style: normal;
      //font-weight: 500;
      //font-size: 18px;
      //text-align: center;
      //color: #003052;

      .sport-label {
        //position: absolute;
        //left: 15px;
        //top: 10px;
        //font-size: 13px;
        //padding: 1px 10px;
        //font-weight: 200;
        //color: $white;

        &-RHYTHMIC_GYMNASTICS {
          background-color: $rgMainColor;
        }
        &-AGG {
          background-color: $aggMainColor;
        }
        &-KA {
          background-color: $kaMainColor;
        }
        &-AKRO {
          background-color: $akroMainColor;
        }
        &-MAG {
          background-color: $magMainColor;
        }
        &-WAG {
          background-color: $wagMainColor;
        }
        &-TG {
          background-color: $tgMainColor;
        }
        &-TUM {
          background-color: $tumMainColor;
        }
        &-TANVO {
          background-color: $tanvoMainColor;
        }
        &-LUMO,
        &-TUL_DANCE,
        &-GFL {
          background-color: $lumoMainColor;
        }
        &-DANCE_LUMO {
          background-color: $danceLumoMainColor;
        }
        &-STARA {
          background-color: $staraMainColor;
        }
        &-performance {
          background-color: #150ff9;
          width: 50px;
          height: 20px;
        }
      }

      &.multi-line {
        line-height: 1;
      }

      &.selected {
        font-weight: 800;
        outline: 2px solid #0060a4;
      }

      &.unselected {
        font-weight: 500;
      }

      &.disabled {
        background-color: $gray-200;
        color: $gray-500;
      }

      span.club-name {
        font-size: 14px;
        color: $gray-500;
        font-weight: 500;
      }

      //@include media-breakpoint-down('xs') {
      //  padding: 24px 16px 8px 16px;
      //}
    }

    .remove-button {
      position: absolute;
      top: 0;
      right: 20px;
      cursor: pointer;
    }
  }
}

.team_dialog {
  background-color: $white;
  box-shadow: 0px 4px 20px rgba(35, 33, 61, 0.1);
  border-radius: 4px;
  overflow: hidden;
  line-height: 120%;

  md-autocomplete {
    height: 20px;
    background: none;
  }

  .label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    padding-left: 40px;
    padding-top: 16px;
  }
  .value {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    padding-top: 16px;
  }

  .form-control {
    font-size: 14px;
  }
}

#edit-team-dialog {
  .container {
    width: 940px;
    margin: 0 auto;
    padding-bottom: 100px;
    background-color: #d7eef9;
    margin-bottom: 87px;
  }

  .form-control {
    margin-top: 20px;
  }

  md-autocomplete {
    background: none;
    input {
      background: $gray-400;
    }
  }
}

#team-editor {
  .form-control {
    margin-left: 20px;
    width: 80%;
  }
}

.event-details {
  &-item {
    margin-bottom: 40px !important;
    margin-top: 40px;

    @include media-breakpoint-down('xs') {
      margin-top: 20px;
    }
  }

  &-tabs {
    margin-bottom: 25px;
    @include media-breakpoint-down('xs') {
      margin-bottom: 0;
    }
  }

  .nav-tabs {
    flex-wrap: nowrap;
    padding-bottom: 15px;

    .nav-link {
      white-space: nowrap;
    }

    @include media-breakpoint-down('xs') {
      flex-wrap: wrap;
      margin: 0;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__content {
    @include media-breakpoint-up('sm') {
      /*// hack to compensate .col-* paddings*/
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  &-summary {
    margin-bottom: 40px;

    &.row {
      @include media-breakpoint-down('md') {
        margin-right: 0;
        margin-left: 0;
      }
    }

    &__news {
      @include media-breakpoint-down('md') {
        margin-bottom: 16px;
      }
    }
  }

  &-registrations {
    &.row {
      @include media-breakpoint-down('md') {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  &__advertisement {
    margin-top: -20px;
    box-shadow: 0 4px 20px rgba(35, 33, 61, 0.1);
  }
}

.event-info-card {
  &__news-item {
    position: relative;
    padding: 0;
    margin-bottom: 16px;
  }

  &__news-text {
    margin: 0 0 5px 0;
    padding-right: 32px;
    &.ql-preview {
      font-family: Rubik;
      p {
        font-size: 15px;
        margin-bottom: 0;
      }
    }
  }

  &__news-ui {
    position: absolute;
    right: 0;
    color: $gray-500;
  }

  &__news-date {
    font-size: 0.75rem;
    padding-right: 16px;
    padding-left: 32px;
  }

  &__news-actions {
    top: 0;
  }

  &__remove,
  &__edit {
    font-size: 14px;
    text-transform: lowercase;
  }

  &__remove {
    margin-right: 16px;
    color: $red;
  }

  &__edit {
    color: $gray-800;
  }

  &__empty {
    text-align: center;
  }

  .card-body {
    max-height: 367px;
    min-height: 70px;
    height: auto;
    overflow-x: hidden;
  }
}

.entry-members {
  display: flex;
  flex-direction: column;

  &__item {
    padding: 0 16px;
    margin-bottom: 8px;

    &--CANCELLED {
      text-decoration: line-through;
    }
  }
}

.event-details-registrations {
  min-height: 70vh;
  .div-table-header {
    border-bottom: $gray-400 1px solid;

    &__item {
      padding: 16px;
      display: flex;
      align-items: center;

      &:after {
        content: none;
      }
    }

    &__title {
      margin-right: 8px;
      color: $secondary;

      &--sortable {
        color: $primary;
      }
    }

    &__icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    /*// styles for sortable items*/
    .div-table-header__icon--unactive {
      display: none;
    }

    .unsorted {
      .div-table-header__title {
        color: $primary;
      }

      .div-table-header__icon {
        & svg {
          stroke: $primary;
        }

        &--active {
          display: none;
        }

        &--unactive {
          display: flex;
        }
      }
    }

    .sorted-asc,
    .sorted-desc {
      .div-table-header__title {
        color: $blue;
      }

      .div-table-header__icon svg {
        stroke: $blue;
      }
    }

    .sorted-asc .div-table-header__icon svg {
      transform: rotate(180deg);
    }
  }

  .icon-container {
    margin-right: 8px;
  }

  .div-table-row {
    padding: 0;

    &__item {
      padding: 16px;
      text-align: left;

      &--icon {
        display: flex;
        align-items: center;
      }
    }
  }

  .div-table-header--mobile {
    .div-table-header__item {
      justify-content: center;
    }
  }

  .div-table-row__item {
    &--mobile {
      padding: 5px 8px;
    }

    &--heading {
      font-weight: bold;
    }
  }

  .btn-tab-nested__toggle {
    background-color: inherit;
    width: inherit;

    &__mobile {
      width: 100%;
    }
  }

  .nav-tabs {
    .nav-link {
      font-size: 22px;
      text-transform: initial;
    }
  }

  .category-registrations {
    button.btn-tab__toggle {
      .sport-label {
        color: $white;
        margin: -16px 0 -16px -16px;
        padding: 16px;
      }
      .category-name {
        padding-left: 16px;
      }
    }
    &__TANVO {
      button.btn-tab__toggle {
        color: $tanvoMainColor;
        .sport-label {
          background-color: $tanvoMainColor;
        }
        .btn-tab__toggle-btn svg path {
          fill: $tanvoMainColor;
        }
      }
    }
    &__RHYTHMIC_GYMNASTICS {
      button.btn-tab__toggle {
        color: $rgMainColor;
        .sport-label {
          background-color: $rgMainColor;
        }
        .btn-tab__toggle-btn svg path {
          fill: $rgMainColor;
        }
      }
    }
    &__AGG {
      button.btn-tab__toggle {
        color: $aggMainColor;
        .sport-label {
          background-color: $aggMainColor;
        }
        .btn-tab__toggle-btn svg path {
          fill: $aggMainColor;
        }
      }
    }
    &__LUMO,
    &__GFL {
      button.btn-tab__toggle {
        color: $lumoMainColor;
        .sport-label {
          background-color: $lumoMainColor;
        }
        .btn-tab__toggle-btn svg path {
          fill: $lumoMainColor;
        }
      }
    }
    &__DANCE_LUMO {
      button.btn-tab__toggle {
        color: $danceLumoMainColor;
        .sport-label {
          background-color: $danceLumoMainColor;
        }
        .btn-tab__toggle-btn svg path {
          fill: $danceLumoMainColor;
        }
      }
    }
    &__KA {
      button.btn-tab__toggle {
        color: $kaMainColor;
        .sport-label {
          background-color: $kaMainColor;
        }
        .btn-tab__toggle-btn svg path {
          fill: $kaMainColor;
        }
      }
    }
    &__AKRO {
      button.btn-tab__toggle {
        color: $akroMainColor;
        .sport-label {
          background-color: $akroMainColor;
        }
        .btn-tab__toggle-btn svg path {
          fill: $akroMainColor;
        }
      }
    }
    &__MAG {
      button.btn-tab__toggle {
        color: $magMainColor;
        .sport-label {
          background-color: $magMainColor;
        }
        .btn-tab__toggle-btn svg path {
          fill: $magMainColor;
        }
      }
    }
    &__WAG {
      button.btn-tab__toggle {
        color: $wagMainColor;
        .sport-label {
          background-color: $wagMainColor;
        }
        .btn-tab__toggle-btn svg path {
          fill: $wagMainColor;
        }
      }
    }
    &__TUM {
      button.btn-tab__toggle {
        color: $tumMainColor;
        .sport-label {
          background-color: $tumMainColor;
        }
        .btn-tab__toggle-btn svg path {
          fill: $tumMainColor;
        }
      }
    }
    &__TG {
      button.btn-tab__toggle {
        color: $tgMainColor;
        .sport-label {
          background-color: $tgMainColor;
        }
        .btn-tab__toggle-btn svg path {
          fill: $tgMainColor;
        }
      }
    }
    &__TUL_DANCE {
      button.btn-tab__toggle {
        color: $tulDanceMainColor;
        .sport-label {
          background-color: $tulDanceMainColor;
        }
        .btn-tab__toggle-btn svg path {
          fill: $tulDanceMainColor;
        }
      }
    }
    &__STARA {
      button.btn-tab__toggle {
        color: $staraMainColor;
        .sport-label {
          background-color: $staraMainColor;
        }
        .btn-tab__toggle-btn svg path {
          fill: $staraMainColor;
        }
      }
    }
    &__ANY {
      button.btn-tab__toggle {
        color: $performanceMainColor;
        .sport-label {
          background-color: $performanceMainColor;
        }
        .btn-tab__toggle-btn svg path {
          fill: $performanceMainColor;
        }
      }
    }
  }
}

.ql-snow {
  ul {
    padding-left: 3em;
    li {
      list-style: initial;
    }
  }
}

.odd .div-table-row {
  background-color: $gray-400;
}

registration-row {
  .registration-detail-container {
    position: relative;
  }

  button.btn-top-right.close-button {
    top: 16px;
    right: 16px;
  }

  .registration-detail {
    width: 100%;
    margin-top: 0;
    section.title {
      display: none;
    }
  }
}
